/*@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');*/
@import url("fonts.css");

html,
body,
#root,
.app,
.content {
  /*height: 100%;*/
  width: 100%;
  font-family: 'montserrat', sans-serif;
  background-color: #fff;
  color: #0b1c08;
}

/* || VARIABLES */

:root {
  /* COLORS */
  --BGCOLOR: #FFF;
  --BORDER-COLOR: #0b1c08;
  --COLOR: #0b1c08;
  --ERROR: firebrick;
  --INACTIVE: lightgray;
  --LINK-COLOR: #0b1c08;
  --LINK-HOVER: #332b15;
  --STATUS-COMPLETE: limegreen;
  --STATUS-OPEN: firebrick;
  --INPUT-INVALID: #F00;
}

h1, h2, h3, h4, h5, h6, .h01, .h02, .h03, .h04, .h05, .h06 {
  font-family: "montserrat-regular", sans-serif;
  color: #151515;
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-bottom: 2.4rem;
}

h3, .h03, h4, .h04 {
  margin-bottom: 1.5rem;
  margin-top: 5px;
}
h5, .h05, h6, .h06 {
  margin-bottom: 1.2rem;
}
h1, .h01 {
  font-size: 3.1rem;
  line-height: 1.35;
  letter-spacing: -.1rem;
}

@media only screen and (max-width: 600px) {
  h1,
  .h01 {
    font-size: 2.6rem;
    letter-spacing: -.07rem;
  }
}

h2, .h02 {
  font-size: 2.4rem;
  line-height: 1.25;
}
h3, .h03 {
  font-size: 2rem;
  line-height: 1.5;
}
h4, .h04 {
  font-size: 1.7rem;
  line-height: 1.76;
}
h5, .h05 {
  font-size: 1.4rem;
  line-height: 1.7;
  text-transform: uppercase;
  letter-spacing: .2rem;
}
h6, .h06 {
  font-size: 1.3rem;
  line-height: 1.85;
  text-transform: uppercase;
  letter-spacing: .2rem;
}

p img {
  margin: 0;
}

p.lead {
  font-family: "montserrat thin", sans-serif;
  font-size: 1.8rem;
  line-height: 1.5;
  color: #121312;
  margin-bottom: 3.6rem;
}

@media only screen and (max-width: 768px) {
  p.lead {
    font-size: 1.3rem;
  }
}


h3.intro-header {
  margin-bottom: 0;
}

h1.intro-header {
  font-family: "montserrat-regular", sans-serif;
  font-size: 3rem;
  margin-top: 0;
  line-height: 1.324;
  color: #151515;
  position: relative;
  padding-bottom: 3rem;
}

h1.intro-header::before {
  display: block;
  content: "";
  height: 2px;
  width: 70px;
  background-color: #80B373;
  position: absolute;
  left: 0;
  bottom: 0;
}

.wide {
  max-width: 1300px;
}

.narrow {
  max-width: 800px;
}


/* -------------------------------------------------------------------
 * responsive:
 * common styles
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 768px) {
  h1.intro-header {
    font-size: 3.1rem;
  }
}

@media only screen and (max-width: 600px) {
  h1.intro-header {
    font-size: 2.6rem;
  }
}


.app {
  display: flex;
  flex: 1 0 auto;
  position: relative;
}

.icon-button {
  width: 48px;
  height: 48px;
  font-size: 1.2rem;
  background-color: transparent;
  border: none;
  color: var(--LINK-COLOR);
  display: grid;
  place-content: center;
}

button.icon-button {
  color: #fff !important;
}

.icon-button:hover,
.icon-button:focus-visible {
  transform: scale(1.2);
  color: var(--LINK-HOVER);
}

button.icon-button:hover {
  color: #7fd470 !important;
}

.icon-button[disabled] {
  visibility: hidden;
}

a {
  text-decoration: none;
}

a,
a:visited {
  color: whitesmoke;
  color: var(--LINK-COLOR);
}

a:hover,
a:focus-visible,
.public__addr a:hover {
  color: var(--LINK-HOVER);
}

.logo {
  width: 70px;
}

::-webkit-scrollbar {
    width: 5px !important;
}

::-webkit-scrollbar-track {
    background-color: #E0E2DF !important;
}

::-webkit-scrollbar-thumb {
    background: #03a455 !important;
}

::-webkit-scrollbar-thumb:hover {
    background: #0b1c08 !important;
}

.center {
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(0,-50%);
}

.MuiTextField-root {
    color: #49a139 !important; /* Text color */
}

.MuiInputBase-input, .MuiSelect-icon, .MuiInputAdornment-root .MuiTypography-root {
    color: #121212 !important;
}

.MuiMenuItem-root.Mui-selected {
    background-color: #295C21 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #0b1c08 !important; /* Border color */
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #49a139 !important; /* Border color */
}

input:-webkit-autofill, .MuiOutlinedInput-input.Mui-disabled {
    -webkit-box-shadow: 0 0 0 100px #fafafa inset !important;
    -webkit-text-fill-color: #0b1c08 !important;
    caret-color: #fff;
    border-radius: inherit;
}

.MuiInputLabel-root.Mui-focused {
    color: #0b1c08 !important; /* Label color when focused */
}

.MuiInputLabel-root {
    color: #0b1c0890 !important; /* Default label color */
}

.Mui-disabled {
    color: #0b1c08 !important;
}

.MuiButton-root {
    background-color: #49a139 !important;
}

.MuiButton-root:hover {
    background-color: #0b1c08 !important;
}

.MuiButton-root.Mui-disabled {
    background-color: #475843 !important;
}

.MuiTablePagination-root {
    color: #0b1c08 !important;
}

.MuiTablePagination-actions .MuiSvgIcon-root {
    fill: #0b1c08 !important;
}

.MuiDataGrid-root {
    --DataGrid-containerBackground: #03a455 !important;
    --DataGrid-pinnedBackground: #03a455 !important;
    --DataGrid-rowBorderColor: rgba(81, 81, 81, 0.4) !important;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: 700 !important;
    color: #fff;
}

.MuiDataGrid-row:nth-of-type(odd) {
    background-color: #fff !important;
    color: #0b1c08;
}

.MuiDataGrid-row:nth-of-type(odd):hover,
.MuiDataGrid-row:nth-of-type(even):hover {
    background-color: #dfdfdf !important;
}

.MuiDataGrid-row:nth-of-type(even) {
    background-color: #efefef !important;
    color: #0b1c08;
}


/* || PAGES */

.public,
.welcome {
  display: flex;
  flex-direction: column;
  gap: 0.02rem;
}

.public {
  padding: 1em;
  flex-grow: 1;
}

.login {
  flex-grow: 1;
  /*border-top: 2px solid var(--BORDER-COLOR);*/
  /*border-bottom: 2px solid var(--BORDER-COLOR);*/
  padding: 1em 0;
}

.public__main {
  flex-grow: 1;
  /*border-top: 2px solid var(--BORDER-COLOR);*/
  /*border-bottom: 2px solid var(--BORDER-COLOR);*/
  padding: 10em 3em;
  height: 90vh;
}

.public__addr, .public__addr a {
  margin-top: 1em;
  font-size: 18px;
  color: #80B373;
}

#home {
  width: 100%;
  height: 630px;
  min-height: 630px;
  background-color: transparent;
  position: relative;
  display: table;
  background-repeat: no-repeat;
  background-size: cover;
}

#home .shadow-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  background: -moz-linear-gradient(left, black 0%, black 20%, transparent 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, black 0%, black 20%, transparent 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, black 0%, black 20%, transparent 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000', GradientType=1);
  /* IE6-9 */
}

#home .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .85;
  background-color: #0b1c08;
}

.no-js #home {
  background: #0b1c08;
}

.home-content {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: bottom;
}

.home-content .contents {
  position: relative;
}

.home-content-left {
  padding: 10rem 0 9rem 20px;
  position: relative;
  width: 60%;
}

.home-content-left h3, .home-content-left h5 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1rem;
  line-height: 1.8;
  text-transform: uppercase;
  letter-spacing: .2rem;
  margin-bottom: 0;
}

.home-content-left h5 {
  font-size: 1.2rem;
  margin-top: 0;
  margin-bottom: 2.8rem;
  text-transform: none;
}

.home-content-left h1 {
  /*font-family: "montserrat-regular", sans-serif;*/
  font-size: 4.5rem;
  line-height: 1.5;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 0;
}

.home-content-left .button.stroke {
  color: #ffffff !important;
  border: 1px solid #ffffff;
  margin: 0 .6rem 1.2rem;
  padding: 0 2.2rem;
  min-width: 188px;
}

.home-content-left .button.stroke span[class*="icon"] {
  margin-right: 5px;
  position: relative;
  top: 2px;
}

.home-content-left .button.stroke:hover,
.home-content-left .button.stroke:focus {
  background-color: white !important;
  color: #000000 !important;
}

.home-image-right {
  display: block;
  position: absolute;
  right: 0;
  top: 40%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-top: 21rem;
  z-index: 500;
  width: 50%;
  text-align: right;
}

.home-image-right img {
  vertical-align: bottom;
  width: 60%;
  margin-right: 100px;
}


/* scroll down */

.home-scrolldown {
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  float: right;
  z-index: 100;
}

.home-scrolldown i {
  padding-left: 9px;
}

.home-scrolldown a:hover,
.home-scrolldown a:focus {
  color: #44c455 !important;
}

html[data-useragent*='MSIE 10.0'] .home-scrolldown,
.oldie .home-scrolldown {
  display: none;
}

.scroll-icon {
  display: inline-block;
  font-family: "montserrat-medium", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: .3rem;
  color: #FFFFFF !important;
  background: transparent;
  position: relative;
  top: 36px;
  right: 42px;
  -webkit-animation: animate-it 3s ease infinite;
  animation: animate-it 3s ease infinite;
}

.scroll-icon i {
  font-size: 2.4rem;
  position: relative;
  bottom: -6px;
}

/* vertical animation */

@-webkit-keyframes animate-it {
  0%,
  60%,
  80%,
  100% {
    -webkit-transform: translateX(0);
  }
  0%,
  60%,
  80%,
  100% {
    transform: translateX(0);
  }
  20% {
    -webkit-transform: translateX(-5px);
  }
  20% {
    transform: translateX(-5px);
  }
  40% {
    -webkit-transform: translateX(20px);
  }
  40% {
    transform: translateX(20px);
  }
}

@keyframes animate-it {
  0%,
  60%,
  80%,
  100% {
    -webkit-transform: translateX(0);
  }
  0%,
  60%,
  80%,
  100% {
    transform: translateX(0);
  }
  20% {
    -webkit-transform: translateX(-5px);
  }
  20% {
    transform: translateX(-5px);
  }
  40% {
    -webkit-transform: translateX(20px);
  }
  40% {
    transform: translateX(20px);
  }
}


/* -------------------------------------------------------------------
 * responsive:
 * home section
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 1300px) {
  .home-image-right {
    right: 5rem;
  }
}

@media only screen and (max-width: 1200px) {
  #home {
    overflow: hidden;
  }
  .home-content {
    vertical-align: middle;
  }
  .home-content-left {
    padding: 18rem 0 12rem 40px;
  }
  .home-content-left h3 {
    font-size: 1.5rem;
  }
  .home-content-left h1 {
    font-size: 4.8rem;
  }
  .home-image-right {
    padding-top: 18rem;
  }
  .home-image-right img {
    vertical-align: top;
    width: 70%;
  }
}

@media only screen and (max-width: 1100px) {
  .home-content-left h1 {
    font-size: 4.6rem;
  }
  .home-content-left h1 br {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .home-content-left {
    width: 60%;
    padding: 18rem 6rem 12rem 4rem;
  }
  .home-content-left h3 {
    font-size: 1.5rem;
  }
  .home-content-left h1 {
    font-size: 4.2rem;
  }
  .home-image-right {
    width: 40%;
    padding-top: 120px;
  }
  .home-image-right img {
    vertical-align: top;
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  #home {
    height: auto;
    min-height: 840px;
  }
  #home,
  .home-content {
    display: block;
    text-align: center;
  }
  .home-content-left {
    width: 94%;
    position: static;
    padding: 15rem 6rem 366px;
    margin: 0 auto;
  }
  .home-content-left h1 {
    margin-bottom: 3.6rem;
  }
  .home-image-right {
    width: 400px;
    right: auto;
    top: auto;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    padding: 0;
    margin-bottom: -378px;
  }
}

@media only screen and (max-width: 768px) {
  #home {
    min-height: auto;
  }
  .home-content-left h3 {
    font-size: 1.4rem;
  }
  .home-content-left h1 {
    font-size: 3.6rem;
  }
}

@media only screen and (max-width: 600px) {
  .home-content-left {
    padding: 12rem 6rem 366px;
  }
  .home-content-left h1 {
    font-size: 3.3rem;
  }
  .home-content-left .button.stroke {
    width: 100%;
    min-width: 0;
    max-width: 280px;
  }
  .home-social-list {
    font-size: 2rem;
    right: 30px;
  }
  .scroll-icon {
    top: 21px;
  }
}

@media only screen and (max-width: 500px) {
  .home-social-list,
  .home-scrolldown {
    display: none;
  }
  .home-content-left {
    padding: 12rem 2.5rem 366px;
    width: 100%;
  }
  .home-content-left h1 {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 400px) {
  .home-content-left {
    padding: 12rem 0 300px;
  }
  .home-content-left .button.stroke {
    margin: 0 0 1.2rem 0;
  }
  .home-image-right {
    width: 320px;
    margin-bottom: -298px;
  }
}

/*.welcome a::before {
  content: "➜ "
}*/


/* -------------------------------------------------------------------
 * block grids paddings
 * ------------------------------------------------------------------- */

.bgrid {
  padding: 0 20px;
}

@media only screen and (max-width: 1024px) {
  .bgrid {
    padding: 0 18px;
  }
}

@media only screen and (max-width: 768px) {
  .bgrid {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 600px) {
  .bgrid {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 400px) {
  .bgrid {
    padding: 0;
  }
}


/* ===================================================================
 *  11. about - (_layout.scss)
 *
 * ------------------------------------------------------------------- */

#about {
  min-height: 786px;
  background: #fafafa;
  padding-top: 9rem;
  overflow: hidden;
}

.about-intro {
  padding-bottom: 3.6rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.about-features .features-list {
  padding-top: 5.4rem;
}

.about-features .feature {
  margin-bottom: 1.8rem;
}

.about-features .feature .icon {
  margin-bottom: .5rem;
  display: inline-block;
  color: #80B373;
  font-size: 4.8rem;
}

.about-features .feature .MuiSvgIcon-root {
  border: 1px solid;
  border-radius: 4px;
}

.about-features .feature h3 {
  font-family: "montserrat-regular", sans-serif;
  font-size: 1.9rem;
  color: #151515;
  margin-bottom: 1.5rem;
  margin-top: 0;
}

.about-how {
  padding-top: 7.2rem;
  padding-bottom: 6rem;
}

.about-how h1.intro-header {
  text-align: center;
}

#about .about-how h1.intro-header::before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.about-how-content {
  position: relative;
  width: 100%;
  max-width: 1000px;
  min-height: 300px;
  margin-top: 7.2rem;
  margin-left: auto;
  margin-right: auto;
  clear: both;
}

.about-how-content::before,
.about-how-content::after {
  content: "";
  display: block;
  width: 1px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
}

.about-how-content::before {
  left: 0;
}

.about-how-content::after {
  left: 50%;
}

.about-how-content .about-how-steps {
  width: 100%;
}

.about-how-content .step {
  padding: 0 40px 1.5rem 60px;
  position: relative;
  min-height: 240px;
}

.about-how-content .step h3 {
  margin-bottom: 2.4rem;
}

.about-how-content .step::before {
  display: block;
  font-family: "muli-bold";
  font-size: 2rem;
  content: attr(data-item);
  color: #ffffff;
  height: 54px;
  width: 54px;
  line-height: 54px;
  text-align: center;
  border-radius: 50%;
  background-color: #80B373;
  box-shadow: 0 0 0 15px #fafafa;
  z-index: 500;
  position: absolute;
  top: -12px;
  left: -27px;
}

.about-bottom-image img {
  vertical-align: bottom;
}


/* -------------------------------------------------------------------
 * responsive:
 * about
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 1200px) {
  .about-how-content {
    max-width: 800px;
  }
}

@media only screen and (max-width: 1024px) {
  .about-intro {
    max-width: 800px;
    text-align: center;
  }
  .about-intro h1::before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .about-intro [class*="col-"] {
    width: 100% !important;
    float: none !important;
    clear: both !important;
    margin-left: 0;
    margin-right: 0;
  }
  .about-how-content {
    max-width: 700px;
  }
}

@media only screen and (max-width: 850px) {
  .about-how-content {
    max-width: 550px;
  }
  .about-how-content::after {
    display: none;
  }
  .about-how-content .step {
    width: 100% !important;
    float: none !important;
    clear: both !important;
    margin-left: 0;
    margin-right: 0;
    padding-right: 18px;
    min-height: auto;
    padding: 0 40px .9rem 60px;
  }
}

@media only screen and (max-width: 768px) {
  .about-features .feature .icon {
    font-size: 4.4rem;
  }
  .about-features .feature h3 {
    font-size: 1.8rem;
  }
  .about-how h3 {
    font-size: 1.9rem;
  }
  .about-how-content::before {
    left: 30px;
  }
  .about-how-content .step {
    padding: 0 10px 0 80px;
  }
  .about-how-content .step::before {
    font-size: 1.6rem;
    height: 45px;
    width: 45px;
    line-height: 45px;
    top: -9px;
    left: 7.5px;
  }
}

@media only screen and (max-width: 650px) {
  .about-features {
    max-width: 600px;
    text-align: center;
  }
  .about-features .feature {
    width: 100% !important;
    float: none !important;
    clear: both !important;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 4.5rem;
  }
  .about-features .feature h3 {
    margin-bottom: 1.5rem;
  }
}

@media only screen and (max-width: 400px) {
  .about-how-content {
    text-align: center;
  }
  .about-how-content::before {
    display: none;
  }
  .about-how-content .step {
    padding: 60px 0 0 0;
  }
  .about-how-content .step::before {
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}


/* || DASH HEADER, CONTAINER & FOOTER */

.dash-header {
  position: static;
  top: 0;
  z-index: 1;
  background-color: #fafafa;
  padding: 0 0.5em;
  border-bottom: 1px solid #0b1c08;
}

.dash-header a,
.dash-header .icon-button,
.dash-footer a,
.dash-footer .icon-button,
footer a {
  color: #fafafa !important;
}

.dash-header__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.dash-header__container--small {
  max-width: 800px;
}

.dash-header__nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  gap: 0.5em;
}

.dash-container {
  padding: 0.75em 0.5em 0.5em;
  flex-grow: 1;
}

.dash-footer, footer {
  font-size: 1rem;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  background-color: #030702;
  color: #fafafa;
  padding: 0.5em;
  /*border-top: 1px solid #fff;*/
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 1em;
  flex-shrink: 0;
}

.dash-footer__button {
  font-size: 1rem;
  width: auto;
  height: auto;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #03a455;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 400px) {
  h1 {
    font-size: 2rem;
  }

  .dash-header__nav {
    gap: 0;
  }

  .icon-button {
    font-size: 1.5rem;
  }
}


@media screen and (min-width: 768px) {

  body {
    font-size: 1.25rem;
  }

  .dash-header {
    padding: 0 1em;
  }

  .dash-container {
    padding: 1em;
  }
}
