/* ===================================================================
 *  03. grid - (_grid.scss)
 *
 * ------------------------------------------------------------------- */

.row {
  width: 94%;
  max-width: 1170px;
  margin: 0 auto;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.row .row {
  width: auto;
  max-width: none;
  margin-left: -20px;
  margin-right: -20px;
}

[class*="col-"],
.bgrid {
  float: left;
}

[class*="col-"]+[class*="col-"].end {
  float: right;
}

[class*="col-"] {
  padding: 0 20px;
}

.col-one {
  width: 8.33333%;
}

.col-two,
.col-1-6 {
  width: 16.66667%;
}

.col-three,
.col-1-4 {
  width: 25%;
}

.col-four,
.col-1-3 {
  width: 33.33333%;
}

.col-five {
  width: 41.66667%;
}

.col-six,
.col-1-2 {
  width: 50%;
}

.col-seven {
  width: 58.33333%;
}

.col-eight,
.col-2-3 {
  width: 66.66667%;
}

.col-nine,
.col-3-4 {
  width: 75%;
}

.col-ten,
.col-5-6 {
  width: 83.33333%;
}

.col-eleven {
  width: 91.66667%;
}

.col-twelve,
.col-full {
  width: 100%;
}


/* -------------------------------------------------------------------
 * medium size devices - (_grid.scss)
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 1024px) {
  .row .row {
    margin-left: -18px;
    margin-right: -18px;
  }
  [class*="col-"] {
    padding: 0 18px;
  }
  .md-two,
  .md-1-6 {
    width: 16.66667%;
  }
  .md-one {
    width: 8.33333%;
  }
  .md-three,
  .md-1-4 {
    width: 25%;
  }
  .md-four,
  .md-1-3 {
    width: 33.33333%;
  }
  .md-five {
    width: 41.66667%;
  }
  .md-six,
  .md-1-2 {
    width: 50%;
  }
  .md-seven {
    width: 58.33333%;
  }
  .md-eight,
  .md-2-3 {
    width: 66.66667%;
  }
  .md-nine,
  .md-3-4 {
    width: 75%;
  }
  .md-ten,
  .md-5-6 {
    width: 83.33333%;
  }
  .md-eleven {
    width: 91.66667%;
  }
  .md-twelve,
  .md-full {
    width: 100%;
  }
}


/* -------------------------------------------------------------------
 * tablets - (_grid.scss)
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 768px) {
  .row {
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
  }
  .row .row {
    padding-left: 0;
    padding-right: 0;
    margin-left: -15px;
    margin-right: -15px;
  }
  [class*="col-"] {
    padding: 0 15px;
  }
  .tab-1-4 {
    width: 25%;
  }
  .tab-1-3 {
    width: 33.33333%;
  }
  .tab-1-2 {
    width: 50%;
  }
  .tab-2-3 {
    width: 66.66667%;
  }
  .tab-3-4 {
    width: 75%;
  }
  .tab-full {
    width: 100%;
  }
}


/* -------------------------------------------------------------------
 *  mobile devices - (_grid.scss)
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 600px) {
  .row {
    padding-left: 25px;
    padding-right: 25px;
  }
  .row .row {
    margin-left: -10px;
    margin-right: -10px;
  }
  [class*="col-"] {
    padding: 0 10px;
  }
  .mob-1-4 {
    width: 25%;
  }
  .mob-1-3 {
    width: 33.33333%;
  }
  .mob-1-2 {
    width: 50%;
  }
  .mob-2-3 {
    width: 66.66667%;
  }
  .mob-3-4 {
    width: 75%;
  }
  .mob-full {
    width: 100%;
  }
}


/* -------------------------------------------------------------------
 * small mobile devices - (_grid.scss)
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 400px) {
  .row .row {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
  }
  [class*="col-"] {
    width: 100% !important;
    float: none !important;
    clear: both !important;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
  [class*="col-"]+[class*="col-"].end {
    float: none;
  }
}


/* ===================================================================
 *  04. block grids - (_grid.scss)
 *
 * ------------------------------------------------------------------- */

[class*="block-"]:after {
  content: "";
  display: table;
  clear: both;
}

.block-1-6 .bgrid {
  width: 16.66667%;
}

.block-1-5 .bgrid {
  width: 20%;
}

.block-1-4 .bgrid {
  width: 25%;
}

.block-1-3 .bgrid {
  width: 33.33333%;
}

.block-1-2 .bgrid {
  width: 50%;
}


/**
 * Clearing for block grid columns. Allow columns with
 * different heights to align properly.
 */

.block-1-6 .bgrid:nth-child(6n+1),
.block-1-5 .bgrid:nth-child(5n+1),
.block-1-4 .bgrid:nth-child(4n+1),
.block-1-3 .bgrid:nth-child(3n+1),
.block-1-2 .bgrid:nth-child(2n+1) {
  clear: both;
}


/* -------------------------------------------------------------------
 * medium size devices - (_grid.scss)
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 1024px) {
  .block-m-1-6 .bgrid {
    width: 16.66667%;
  }
  .block-m-1-5 .bgrid {
    width: 20%;
  }
  .block-m-1-4 .bgrid {
    width: 25%;
  }
  .block-m-1-3 .bgrid {
    width: 33.33333%;
  }
  .block-m-1-2 .bgrid {
    width: 50%;
  }
  .block-m-full .bgrid {
    width: 100%;
    clear: both;
  }
  [class*="block-m-"] .bgrid:nth-child(n) {
    clear: none;
  }
  .block-m-1-6 .bgrid:nth-child(6n+1),
  .block-m-1-5 .bgrid:nth-child(5n+1),
  .block-m-1-4 .bgrid:nth-child(4n+1),
  .block-m-1-3 .bgrid:nth-child(3n+1),
  .block-m-1-2 .bgrid:nth-child(2n+1) {
    clear: both;
  }
}


/* -------------------------------------------------------------------
 * tablets - (_grid.scss)
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 768px) {
  .block-tab-1-6 .bgrid {
    width: 16.66667%;
  }
  .block-tab-1-5 .bgrid {
    width: 20%;
  }
  .block-tab-1-4 .bgrid {
    width: 25%;
  }
  .block-tab-1-3 .bgrid {
    width: 33.33333%;
  }
  .block-tab-1-2 .bgrid {
    width: 50%;
  }
  .block-tab-full .bgrid {
    width: 100%;
    clear: both;
  }
  [class*="block-tab-"] .bgrid:nth-child(n) {
    clear: none;
  }
  .block-tab-1-6 .bgrid:nth-child(6n+1),
  .block-tab-1-6 .bgrid:nth-child(5n+1),
  .block-tab-1-4 .bgrid:nth-child(4n+1),
  .block-tab-1-3 .bgrid:nth-child(3n+1),
  .block-tab-1-2 .bgrid:nth-child(2n+1) {
    clear: both;
  }
}


/* -------------------------------------------------------------------
 * mobile devices - (_grid.scss)
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 600px) {
  .block-mob-1-6 .bgrid {
    width: 16.66667%;
  }
  .block-mob-1-5 .bgrid {
    width: 20%;
  }
  .block-mob-1-4 .bgrid {
    width: 25%;
  }
  .block-mob-1-3 .bgrid {
    width: 33.33333%;
  }
  .block-mob-1-2 .bgrid {
    width: 50%;
  }
  .block-mob-full .bgrid {
    width: 100%;
    clear: both;
  }
  [class*="block-mob-"] .bgrid:nth-child(n) {
    clear: none;
  }
  .block-mob-1-6 .bgrid:nth-child(6n+1),
  .block-mob-1-5 .bgrid:nth-child(5n+1),
  .block-mob-1-4 .bgrid:nth-child(4n+1),
  .block-mob-1-3 .bgrid:nth-child(3n+1),
  .block-mob-1-2 .bgrid:nth-child(2n+1) {
    clear: both;
  }
}


/* -------------------------------------------------------------------
 * stack on small mobile devices - (_grid.scss)
 * ------------------------------------------------------------------- */

@media only screen and (max-width: 400px) {
  .stack .bgrid {
    width: 100% !important;
    float: none !important;
    clear: both !important;
    margin-left: 0;
    margin-right: 0;
  }
}


/* ===================================================================
 *  05. MISC  - (_grid.scss)
 *
 * ------------------------------------------------------------------- */

.group:after {
  content: "";
  display: table;
  clear: both;
}


/* Misc Helper Styles */

.is-hidden {
  display: none;
}

.is-invisible {
  visibility: hidden;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.overflow-hidden {
  overflow: hidden;
}

.remove-bottom {
  margin-bottom: 0;
}

.half-bottom {
  margin-bottom: 1.5rem !important;
}

.add-bottom {
  margin-bottom: 3rem !important;
}

.no-border {
  border: none;
}

.full-width {
  width: 100%;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.align-center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
